<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @datachange="getHeader(true)" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="작성부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="0M"
            label="등록일"
            name="dailyResultDts"
            v-model="searchParam.dailyResultDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="폐수배출시설 운영일지"
      tableId="grid"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-all-cons-report-manage',
  data() {
    return {
      originalCol: [
        {
          name: 'plantName',
          field: 'plantName',
          label: '사업장',
          align: 'center',
          sortable: true,
          style: 'width:120px',
          fix: true,
        },
        {
          name: 'dailyResultDt',
          field: 'dailyResultDt',
          label: '년월일',
          align: 'center',
          sortable: true,
          type: 'link',
          style: 'width:120px',
          fix: true,
        },
        {
          name: 'approvalStatusName',
          field: 'approvalStatusName',
          label: '상태',
          align: 'center',
          sortable: true,
          style: 'width:100px',
        },
        {
          label: '결재',
          align: 'center',
          child: [
            {
              name: 'approveName1',
              field: 'approveName1',
              label: '대표자',
              align: 'center',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'approveName2',
              field: 'approveName2',
              label: '환경기술인',
              align: 'center',
              style: 'width:120px',
              sortable: false,
            },
          ]
        },
        {
          name: 'oprDtStr',
          field: 'oprDtStr',
          label: '가동시간',
          align: 'center',
          sortable: false,
          style: 'width:160px',
        },
        {
          name: 'useAmount',
          field: 'useAmount',
          label: '용수사용량(L)',
          align: 'center',
          sortable: false,
          style: 'width:120px',
        },
        {
          name: 'wasteAmount',
          field: 'wasteAmount',
          label: '폐수발생량(L)',
          align: 'center',
          sortable: false,
          style: 'width:120px',
        },
        {
          label: '폐수 위탁처리',
          align: 'center',
          child: [
            {
              name: 'entrustAmount',
              field: 'entrustAmount',
              label: '위탁량(L)',
              align: 'center',
              sortable: false,
              style: 'width:120px',
            },
            {
              name: 'entrustNo',
              field: 'entrustNo',
              label: '위탁ㆍ수탁 확인서<br>일련번호',
              align: 'center',
              sortable: false,
              style: 'width:160px',
            },
            {
              name: 'entrustVendorName',
              field: 'entrustVendorName',
              label: '처리업소명',
              align: 'center',
              sortable: false,
              style: 'width:150px',
            },
          ]
        },
        {
          name: 'stoAmount',
          field: 'stoAmount',
          label: '보관폐수량(L)',
          align: 'center',
          sortable: false,
          style: 'width:120px',
        },
        {
          name: 'remark',
          field: 'remark',
          label: '기타 중요사항',
          align: 'center',
          sortable: false,
          style: 'width:200px',
        },
      ],
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        dailyResultDts: [],
        plantCd: null,
        deptCd: '',
        envWaterMstAdditiveIds: [],
      },
      editable: true,
      listUrl: '',
      headerUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.water.entrust.result.list.url;
      this.headerUrl = selectConfig.env.water.mst.additive.list.url;
      this.getHeader(false);
    },
    getHeader(_ischange) { 
      this.grid.columns = [];
      this.grid.data = [];
      this.$_.extend(this.grid.columns, this.originalCol)
      this.$http.url = this.headerUrl;
      this.$http.param = {plantCd: this.searchParam.plantCd, useFlag: 'Y'}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        let _child = [];
        this.searchParam.envWaterMstAdditiveIds = [];
        if (_result.data.length > 0) {
          this.$_.forEach(_result.data, item => {
            this.searchParam.envWaterMstAdditiveIds.push(item.envWaterMstAdditiveId);
            _child.push(
              {
                name: item.envWaterMstAdditiveId,
                field: item.envWaterMstAdditiveId,
                label: item.envWaterMstAdditiveName,
                type: 'cost',
                style: 'width: 90px',
                sortable: false,
              }
            )
          });
          this.grid.columns.splice(5,0,
            {
              label: '원료사용량',
              align: 'center',
              child: _child,
            }
          )
          if (!_ischange) {
            this.getList();
          }
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '수질 기준정보에서 해당사업장에 원료/첨가제정보가 없습니다.', // 선택된 항목이 없습니다.
            type: 'warning', // success / info / warning / error
          });
        }
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./dailyAllConsReportManageDetail.vue"}`);
      this.popupOptions.title = '폐수 전량위탁 운영일지 상세';
      this.popupOptions.param = {
        envWaterEntrustResultId: result ? result.envWaterEntrustResultId : '',
        plantCd: result ? result.plantCd : '',
        approvalStatusCd: result ? result.approvalStatusCd : null,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
